<!-- 实验中心一体化信息管理平台 -->
<template>
  <div class="clinic">
    <!-- 实验中心一体化信息管理平台 -->
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'实验中心一体化信息管理平台'" />
    <Business :business="business" />
    <appsOfImg :appsData="appsData" :IsKnowMore="false" />
    <!-- 系统功能 -->
    <div class="system-container">
      <div class="system">
        <div class="system-left">
          <Subhead :enVal="'System Workloads'" :znVal="'系统功能'" :isBlue="1" />
          <div class="system-left-text">{{ systemData.text | myTrim }}</div>
        </div>
        <img :src="systemData.imgUrl" alt="" class="system-rihgt">
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
import appsOfImg from "./components/appsOfImg.vue"

export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead,
    appsOfImg
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner8.png"),
      business: {
        text: "实验中心是科研工作的主要运行平台，是从事科学研究实验工作的基地，实验室中心不仅要管理“人、机、物、法、环”，而且承担项目、课题的研究工作，需要对项目的过程、经费的使用进行科学合规管理。又因为科研工作的创造性，需要对实验数据进行采集整理、存储，统计学分析，在新的大环境下，科学研究趋向以多中心、多合作机构联合方式共同完成，在科研工作中产生海量的试验数据也成为新型的实验室资源，因而更要求实现信息数据共享，实验资源共享，实现高效率的协同办公。",
        Imgs: []
      },
      appsData: {
        text: "实验室一体化信息管理系统是实验中心信息化建设的整体解决方案，平台架构基于“1个账号+1个平台+N个终端”的应用模式，运用云计算、大数据、物联网、移动互联和AI等技术，通过梳理用户的业务流、数据流、审批流，结合管理制度，对流程再造优化，建立起一套完整的，高效的，安全的实验室信息化管理服务平台。在一体化信息管理系统平台上，建立实验中心数据库（中心），实现实验数据的采集整理、分析、处理、查询。此外，对实验室人员、仪器设备、安全等方面进行综合管理，建立共享机制，实现实验中心整体资源的共享，充分发挥实验中心作为科学研究实验工作的主要平台的作用。",
        imgUrl: require("@/assets/images/cases/content/apps-center01.png")
      },
      systemData: {
        text: "系统主要应用包括：人员管理、权限管理、培训管理、仪器设备管理、试剂耗材管理、仪器设备预约管理、课题管理、经费管理、送样检测管理、实验室环境监测管理、实验室门禁管理、实验室监控管理、样本库管理、实验产物库管理、基因数据库管理、实验数据管理、数据分析工具、文件管理 、信息公告管理、门户网站、手机端应用等。",
        imgUrl: require("@/assets/images/cases/content/apps-center02.png")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.system-container {
  max-width: 1920px;
  background: #F0F3F9;
  margin: 0 auto;

  .system {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .system-left {
      .system-left-text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 54px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }

    .system-rihgt {
      display: block;
      width: 517px;
      height: 378px;
      margin: 61px 0 61px 84px;
    }
  }

}
</style>